import React, { useEffect, useMemo, useState } from 'react'
import Select from '../select'
import * as style from './style.module.css'

const Table = ({ columns, rows, noSort }) => {
  const [sortBy, setSortBy] = useState(columns[0].value)
  const [sortOrder, setSortOrder] = useState('asc')

  useEffect(() => {
    if (!localStorage) return

    const savedSortBy = localStorage.getItem('sortBy')
    if (savedSortBy) setSortBy(savedSortBy)

    const savedSortOrder = localStorage.getItem('sortOrder')
    if (savedSortOrder) setSortOrder(savedSortOrder)
  }, [])

  const sortedRows = useMemo(() => {
    if (noSort) return [...rows]

    const column = columns.find(({ value }) => value === sortBy)
    const sortedRows = [...rows]
    if (column?.type === 'string') {
      sortedRows.sort((a, b) => a[sortBy].value?.localeCompare(b[sortBy].value))
    } else if (column?.type === 'number') {
      sortedRows.sort((a, b) => a[sortBy].value - b[sortBy].value)
    }

    if (sortOrder === 'desc') sortedRows.reverse()

    return sortedRows
  }, [columns, rows, noSort, sortBy, sortOrder])

  const onSortByChange = (e) => {
    const { value } = e.target
    setSortBy(value)
    localStorage.setItem('sortBy', value)

    if (value.includes('Score'))
      onSortOrderChange({ target: { value: 'desc' } })
  }

  const onSortOrderChange = (e) => {
    const { value } = e.target
    setSortOrder(value)
    localStorage.setItem('sortOrder', value)
  }

  return (
    <div className={style.tableContainer}>
      {!noSort && (
        <div className={style.sortByWrapper}>
          <Select
            id="sortBy"
            label="Sort By:"
            onChange={onSortByChange}
            options={columns}
            value={sortBy}
          />
          <Select
            id="sortOrder"
            onChange={onSortOrderChange}
            options={[
              { label: 'Asc', value: 'asc' },
              { label: 'Desc', value: 'desc' },
            ]}
            value={sortOrder}
          />
        </div>
      )}
      <div className={style.tableWrapper}>
        <table className={style.table}>
          <thead>
            <tr>
              {columns.map(({ label, value }) => (
                <th key={value}>{label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sortedRows.map((row, i) => (
              <tr key={i}>
                {columns.map(({ value }) => (
                  <td className={style[value]} key={value}>
                    {row[value].component || row[value].value}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Table
