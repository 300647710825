import React from 'react'
import * as style from './style.module.css'
import Layout from '../layout'
import StatsTable from '../stats-table'
import StatsChart from '../stats-chart'
import BoroughStatsTable from '../borough-stats-table'

const StatsPageLayout = ({ iconImageNodes, nodes }) => {
  return (
    <Layout fluid title="Stats">
      <div className={style.statsPageLayout}>
        <StatsChart nodes={nodes} />
        <StatsTable iconImageNodes={iconImageNodes} nodes={nodes} />
        <BoroughStatsTable nodes={nodes} />
      </div>
    </Layout>
  )
}

export default StatsPageLayout
