// extracted by mini-css-extract-plugin
export var avgScore = "style-module--avgScore---nzrz";
export var avgScoreTopTen = "style-module--avgScoreTopTen--M0dLk";
export var bagelScore = "style-module--bagelScore--HcLom";
export var borough = "style-module--borough--h5gWP";
export var creamCheeseScore = "style-module--creamCheeseScore--+l-11";
export var sortByWrapper = "style-module--sort-by-wrapper--RYTlB";
export var storeScore = "style-module--storeScore--ulclC";
export var table = "style-module--table--fMm49";
export var tableContainer = "style-module--table-container--uXJo0";
export var tableWrapper = "style-module--table-wrapper--luUTb";
export var total = "style-module--total--HOD0i";