import React from 'react'
import Table from '../table'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import * as style from './style.module.css'
import keyBy from 'lodash.keyby'
import { getOverallScore } from '../../utils'
import { iconMap } from '../../utils/constants'

const columns = [
  { label: 'Store Name', type: 'string', value: 'storeName' },
  { label: 'Borough', type: 'string', value: 'borough' },
  { label: 'Neighborhood', type: 'string', value: 'neighborhood' },
  { label: 'Store Score', type: 'number', value: 'storeScore' },
  { label: 'Bagel Score', type: 'number', value: 'bagelScore' },
  { label: 'Spread Score', type: 'number', value: 'creamCheeseScore' },
  { label: 'Total Score', type: 'number', value: 'total' },
]

const StatsTable = ({ iconImageNodes, nodes }) => {
  const iconImageNodesMap = keyBy(iconImageNodes, 'name')

  const rows = nodes
    .filter(({ frontmatter }) => !frontmatter.genesisEverything)
    .map(({ frontmatter, reviewPath }) => {
      const {
        bagelIconColor,
        bagelScore,
        borough,
        creamCheeseNA,
        creamCheeseScore,
        isClosed,
        neighborhood,
        overallIconColor,
        storeNA,
        storeName,
        storeScore,
      } = frontmatter

      return {
        bagelScore: { value: bagelScore },
        borough: { value: borough },
        creamCheeseScore: { value: creamCheeseScore || '-' },
        fullReview: {
          component: (
            <Link to={reviewPath}>
              <GatsbyImage
                alt={overallIconColor}
                image={getImage(
                  iconImageNodesMap[
                    iconMap[overallIconColor] + (isClosed ? '-closed' : '')
                  ]
                )}
              />
            </Link>
          ),
          value: bagelIconColor,
        },
        neighborhood: { value: neighborhood },
        storeName: {
          component: (
            <Link className={style.storeNameLink} to={reviewPath}>
              {storeName}
            </Link>
          ),
          value: storeName,
        },
        storeScore: { value: storeScore || '-' },
        total: {
          value: getOverallScore(
            bagelScore,
            !creamCheeseNA && creamCheeseScore,
            !storeNA && storeScore
          ),
        },
      }
    })

  return (
    <div className={style.statsTable}>
      <Table columns={columns} rows={rows} />
    </div>
  )
}

export default StatsTable
