import React from 'react'
import { getOverallScore } from '../../utils'
import sortBy from 'lodash.sortby'
import Table from '../table'

const columns = [
  { label: 'Borough', value: 'borough' },
  { label: 'Stores', value: 'numOfStores' },
  { label: 'Average Score', value: 'avgScore' },
  { label: 'Average of Top 10', value: 'avgScoreTopTen' },
]

const rowKeys = ['Manhattan', 'Brooklyn', 'Queens', 'Staten Island', 'Bronx']

const BoroughStatsTable = ({ nodes }) => {
  const filteredNodes = nodes
    .filter(({ frontmatter }) => !frontmatter.genesisEverything)
    .map(({ frontmatter }) => {
      const {
        bagelScore,
        creamCheeseNA,
        creamCheeseScore,
        storeNA,
        storeScore,
      } = frontmatter
      const overallScore = Number(
        getOverallScore(
          bagelScore,
          !creamCheeseNA && creamCheeseScore,
          !storeNA && storeScore
        )
      )
      return { ...frontmatter, overallScore }
    })

  const sortedNodes = sortBy(filteredNodes, 'overallScore')

  const aggregateMap = sortedNodes.reduceRight(
    (prev, { borough, overallScore }) => {
      prev[borough].numOfStores++
      prev[borough].avgScoreTotal += overallScore
      if (prev[borough].topTenCount < 10) {
        prev[borough].topTenTotal += overallScore
        prev[borough].topTenCount++
      }
      return prev
    },
    {
      Brooklyn: {
        numOfStores: 0,
        avgScoreTotal: 0,
        topTenTotal: 0,
        topTenCount: 0,
      },
      Bronx: {
        numOfStores: 0,
        avgScoreTotal: 0,
        topTenTotal: 0,
        topTenCount: 0,
      },
      Manhattan: {
        numOfStores: 0,
        avgScoreTotal: 0,
        topTenTotal: 0,
        topTenCount: 0,
      },
      Queens: {
        numOfStores: 0,
        avgScoreTotal: 0,
        topTenTotal: 0,
        topTenCount: 0,
      },
      'Staten Island': {
        numOfStores: 0,
        avgScoreTotal: 0,
        topTenTotal: 0,
        topTenCount: 0,
      },
    }
  )

  const rows = rowKeys.map((key) => {
    return {
      borough: { value: key },
      numOfStores: { value: aggregateMap[key].numOfStores },
      avgScore: {
        value: (
          aggregateMap[key].avgScoreTotal / aggregateMap[key].numOfStores
        ).toFixed(['Manhattan', 'Brooklyn'].includes(key) ? 5 : 2),
      },
      avgScoreTopTen: {
        value: (aggregateMap[key].topTenTotal / 10).toFixed(2),
      },
    }
  })

  return <Table columns={columns} noSort rows={rows} />
}

export default BoroughStatsTable
