import React from 'react'
import { graphql } from 'gatsby'
import StatsPageLayout from '../components/stats-page-layout'

const StatsPage = ({ data }) => {
  const { allFile, allMarkdownRemark } = data
  return (
    <StatsPageLayout
      iconImageNodes={allFile.nodes}
      nodes={allMarkdownRemark.nodes}
    />
  )
}

export const query = graphql`
  query {
    allFile(filter: { sourceInstanceName: { eq: "icons" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(placeholder: DOMINANT_COLOR, width: 20)
        }
        name
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(/reviews/)/" } }
    ) {
      nodes {
        frontmatter {
          bagelIconColor
          bagelScore
          bagelX
          bagelY
          borough
          creamCheeseIconColor
          creamCheeseNA
          creamCheeseScore
          creamCheeseX
          creamCheeseY
          genesisEverything
          isClosed
          neighborhood
          overallIconColor
          storeName
          storeIconColor
          storeNA
          storeScore
          storeX
          storeY
          toppingIconColor
          toppingNA
          toppingX
          toppingY
        }
        reviewPath: gatsbyPath(
          filePath: "/reviews/{MarkdownRemark.frontmatter__storeName}"
        )
      }
    }
  }
`

export default StatsPage
