import React from 'react'
import * as style from './style.module.css'

const Select = ({ id, label, onChange, options, value }) => {
  return (
    <>
      {label && (
        <label className={style.label} htmlFor={id}>
          {label}
        </label>
      )}
      <select
        className={style.select}
        id={id}
        name={id}
        onChange={onChange}
        value={value}
      >
        {options.map(({ label, value }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
    </>
  )
}

export default Select
