// TODO: change icon size based on viewport size

import React, { useEffect, useMemo, useState } from 'react'
import ReviewChart from '../review-chart'
import Select from '../select'
import * as style from './style.module.css'

const chartKeys = ['bagel', 'creamCheese', 'store', 'topping']

const chartLabelMap = {
  bagel: {
    title: 'Bagel Size and Texture',
    xMaxLabel: 'X-Large',
    xMinLabel: 'Small',
    yMaxLabel: 'Crackly',
    yMinLabel: 'Chewy',
  },
  topping: {
    title: 'Seed and Salt Density',
    xMaxLabel: 'Topping Dense',
    xMinLabel: 'Topping Light',
    yMaxLabel: 'High Salt Ratio',
    yMinLabel: 'Low Salt Ratio',
  },
  creamCheese: {
    title: 'Spread Ripeness and Texture',
    xMaxLabel: 'Dairy Forward',
    xMinLabel: 'Dairy Latent',
    yMaxLabel: 'Fine Scallion',
    yMinLabel: 'Coarse Scallion',
  },
  store: {
    title: 'Store Style and Services',
    xMaxLabel: 'Contemporary',
    xMinLabel: 'Classic',
    yMaxLabel: 'Variety of Services',
    yMinLabel: 'Focused Services',
  },
}

const chartTypes = [
  { label: 'Store', value: 'store' },
  { label: 'Bagel', value: 'bagel' },
  { label: 'Toppings', value: 'topping' },
  { label: 'Cream Cheese', value: 'creamCheese' },
]

const StatsChart = ({ nodes }) => {
  const [chartType, setChartType] = useState('store')

  useEffect(() => {
    if (!localStorage) return
    const savedChartType = localStorage.getItem('chartType')
    if (savedChartType) setChartType(savedChartType)
  }, [])

  const chartParams = useMemo(
    () =>
      nodes.reduce(
        (prev, { frontmatter: item, reviewPath }) => {
          if (!item.genesisEverything) {
            chartKeys.forEach((key) => {
              prev[key].push([
                item[`${key}X`],
                item[`${key}Y`],
                item[`${key}IconColor`],
                reviewPath,
                item.isClosed,
              ])
            })
          }
          return prev
        },
        { bagel: [], creamCheese: [], store: [], topping: [] }
      ),
    [nodes]
  )

  const onChartTypeChange = (e) => {
    const { value } = e.target
    setChartType(value)
    localStorage.setItem('chartType', value)
  }

  return (
    <div>
      <div className={style.selectWrapper}>
        <Select
          id="chartType"
          onChange={onChartTypeChange}
          options={chartTypes}
          value={chartType}
        />
      </div>
      <div className={style.chartWrapper}>
        <ReviewChart
          iconSize={25}
          isClosed={chartLabelMap[chartType].isClosed}
          title={chartLabelMap[chartType].title}
          titleLarge
          values={chartParams[chartType]}
          xMaxLabel={chartLabelMap[chartType].xMaxLabel}
          xMinLabel={chartLabelMap[chartType].xMinLabel}
          yMaxLabel={chartLabelMap[chartType].yMaxLabel}
          yMinLabel={chartLabelMap[chartType].yMinLabel}
        />
      </div>
    </div>
  )
}

export default StatsChart
